'use strict';

module.exports = function () {
    // Prevent duplicate listeners from being set
    if (!$(document).data('campaignSignUpListenerBound')) {
        $(document).on('submit', 'form.campaign-interest-signup', function (e) {
            e.preventDefault();
            var $form = $(this);
            var $parent = $form.parent();
            var url = $form.attr('action');
            var method = $form.attr('method');
            $.spinner().start();
            $('.btn-email').prop('disabled', true);

            $.ajax({
                url: url,
                type: method,
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.success) {
                        $form.hide();
                        $form.find('.js-campaign-signup-invalid').text('');
                        $parent.alert({
                            content: data.msg,
                            theme: 'success',
                            placement: 'inline',
                            dismissible: false
                        });

                        if ('dtmLayer' in data && 'digitalData' in window) {
                            if (data.dtmLayer.page && data.dtmLayer.page.form &&
                                window.digitalData.page && window.digitalData.page.form) {
                                window.digitalData.page.form.registrationType = data.dtmLayer.page.form.registrationType;
                            }

                            if (data.dtmLayer.user) {
                                window.digitalData.user = data.dtmLayer.user;
                            }
                        }
                    } else if (data.msg) {
                        $form.find('.js-campaign-signup-invalid').text(data.msg).show();
                    }
                },
                complete: function () {
                    $.spinner().stop();
                    $('.btn-email').prop('disabled', false);
                }
            });
        });

        $(document).data('campaignSignUpListenerBound', true);
    }
};
